<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mt-2">
        <h2 class="text-center mt-2">Dynamic SO</h2>
        <div class="card-body">
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Opps .. Something is wrong
              </b-alert>
            </b-col>
          </b-row>
          <div class="col-md-12 pt-2">
            <div class="form-group row">
              <span class="col-sm-2">Periode</span>
              <div class="col-sm-auto">
                <v-date-picker
                  v-model="range"
                  mode="date"
                  :masks="masks"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div id="betweenPeriod" class="rows">
                      <input
                        id="startPeriod"
                        class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <span class="p-2">to</span>
                      <input
                        id="endPeriod"
                        class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                        :max-date="new Date()"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group row">
              <span class="col-sm-2">Offer Type</span>
              <div class="col-sm-2">
                <b-form-select
                  id="searchOfferMode"
                  class="flex-grow"
                  v-model="offerType"
                  track-by="value"
                  :options="offerTypeOptions"
                  required
                >
                </b-form-select>
              </div>
              <div class="col-sm-2">
                <b-form-select
                  id="currency"
                  class="mb-2"
                  v-model="statusSo"
                  :options="statusOptions"
                  required
                ></b-form-select>
              </div>
              <div class="col-sm-1">
                <button class="btn btn-block btn-primary" @click="fetchData">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto font-weight-bold">Total :</label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :isLoading="isLoading"
            responsive="xl"
            show-empty
            striped
            :busy="isLoading"
            :fields="fields"
            hover
            :items="items"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(orderline_id)="row">
              <div style="width: 120px">
                {{ row.item?.orderline_id ? row.item?.orderline_id : '-' }}
              </div>
            </template>

            <template #cell(so_number)="row">
              <div style="width: 120px">
                {{ row.item?.so_number ? row.item?.so_number : '-' }}
              </div>
            </template>

            <template #cell(so_period)="row">
              <div style="width: 120px">
                {{ row.item?.so_period ?formatDate(row.item?.so_period ): '-' }}
              </div>
            </template>

            <template #cell(status_message)="row">
              <div v-if="row.item.attempt === 0" style="width: 250px">
                  Waiting for currency
              </div>
              <div v-else style="width: 250px">
                {{ row.item?.status_message ? row.item?.status_message : '-' }}
              </div>
            </template>

            <template #cell(offer_type)="row">
              <div style="width: 90px">
                <div v-if="row.item?.offer_type == 1">
                  <span class="badge badge-primary">single</span>
                </div>
                <div v-if="row.item?.offer_type == 2">
                  <span class="badge badge-secondary">subscription</span>
                </div>
                <div v-if="row.item?.offer_type == 3">
                  <span class="badge badge-warning">bundle</span>
                </div>
                <div v-if="row.item?.offer_type == 4">
                  <span class="badge badge-purple">buffet</span>
                </div>
              </div>
            </template>
            
            <template #cell(amount)="row">
              <div style="width: 100px">
                {{ row.item?.amount ? formatNumber(row.item?.amount) : '-' }}
              </div>
            </template>

            <template #cell(status_so)="row">
              <span
                v-if="
                  successResponse.includes(parseInt(row.item?.status_so, 10))
                "
                class="badge badge-success"
              >
                succeed
              </span>
              <span v-else class="badge badge-danger">failed</span>
            </template>

            <template #cell(created)="row">
              <div style="width: 100px">
                {{
                  row.item.created ? formatDate(row.item.created) : '-'
                }}
              </div>
            </template>

            <template #cell(modified)="row">
              <div style="width:100px">
                {{
                  row.item.modified ? formatDate(row.item.modified) : '-'
                }}
              </div>
            </template>

            <template #cell(action)="row">
              <b-dropdown
              v-if="!successResponse.includes(parseInt(row.item?.status_so, 10)) && row.item.attempt !== 0"
                split
                split-variant="outline-primary"
                variant="primary"
                text="actions"
                class="m-2"
              >
                <b-dropdown-item variant="primary" @click="resendSO(row.item)">
                  <i class="fa fa-send"></i>
                  Resend SO
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import constant from '../../store/constant';
import { EventBus } from '../../event-bus';
const statusSo = [
  {
    text: 'All Status SO',
    value: '',
  },
  {
    value: '200',
    text: 'Succeed',
  },
  {
    value: '400',
    text: 'Failed',
  },
];
const successResponse = [200, 201];
export default {
  name: 'DynamicSO',
  data() {
    return {
      successResponse,
      currentPage: 1,
      perPage: 50,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'ticket_number', label: 'Ticket Number' },
        { key: 'so_number', label: 'SO Number' },
        { key: 'so_period', label: 'SO Period' },
        { key: 'offer_type', label: 'Offer Type' },
        { key: 'status_so', label: 'Status' },
        { key: 'status_message', label: 'Message' },
        { key: 'created', label: 'created' },
        { key: 'modified', label: 'modified' },
        { key: 'action', label: '' },
      ],
      typing: '',
      searchForm: null,
      statusSo: '',
      statusOptions: { ...statusSo },
      offerType: '',
      offerTypeOptions: [
        { value: '', text: 'All Offer' },
        { value: constant.OFFER_TYPE.SINGLE, text: 'Single' },
        { value: constant.OFFER_TYPE.BUNDLE, text: 'Bundle' },
        { value: constant.OFFER_TYPE.SUBSCRIPTIONS, text: 'Subscriptions' },
        { value: constant.OFFER_TYPE.BUFFET, text: 'Buffet' },
      ],
      publisher: [],
      publisherSearchOptions: [],
      isPublisherSearch: false,
      range: {
        start: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.dynamicSO.isLoading,
      isError: (state) => state.dynamicSO.isError,
      totalRows: (state) => state.dynamicSO.totalRows,
      items: (state) => state.dynamicSO.items,
    }),
  },
  watch: {
    currentPage() {
      this.fetchData();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Dynamic Item SO | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('dynamicSO', ['fetchAllDynamicSO','postResendSO']),

    fetchData() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.searchForm) payload.q = this.searchForm;
      if (this.statusSo) payload.status_so = this.statusSo;
      if (this.offerType) payload.offer_type = this.offerType;
      if (this.range.start && this.range.end) {
        payload.start_period = moment(this.range.start).format('YYYY-MM-DD');
        payload.end_period = moment(this.range.end).format('YYYY-MM-DD');
      }
      this.fetchAllDynamicSO(payload);
    },

    formatDateString(date) {
      return new Date(date).toString();
    },

    formatDate(date) {
     return moment(date).format('DD MMM YYYY');
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
    onItemStatusChange() {
      this.fetchData();
    },
    setOfferType() {
      this.fetchData();
    },
    resendSO(item) {
      this.postResendSO(item).then(()=> {
        EventBus.$emit('show-toast', {
          message: 'Update has been success',
          type: 'success',
        });
      })
      // this.$swal({
      //   icon: 'warning',
      //   toast: true,
      //   position: 'top-end',
      //   title: 'Upcoming Feature',
      //   timer: 5000,
      // });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  font-style: italic;
}
.badge-purple {
  background-color: #ff06dc;
}
.badge-success {
  background-color: #29932b;
}
</style>
